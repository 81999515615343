import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function FilterIcon({ width = 24, height = 24, color: iconColor,className, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps} className={className}>

            <svg width={width} height={height} color={color} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 8L20 8" stroke={color} strokeLinecap="round" />
                <path d="M4 16L14 16" stroke={color} strokeLinecap="round" />
                <ellipse
                    cx="7"
                    cy="8"
                    rx="3"
                    ry="3"
                    transform="rotate(90 7 8)"
                    stroke={color}
                    strokeLinecap="round"
                />
                <ellipse
                    cx="17"
                    cy="16"
                    rx="3"
                    ry="3"
                    transform="rotate(90 17 16)"
                    stroke={color}
                    strokeLinecap="round"
                />
            </svg>
        </SVGContainer>
    );
}

export default FilterIcon;
