import BaseService from './BaseService';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, REFRESH_SUCCESS } from '../Redux/Action';
import Http from '../Http';
import axios from 'axios';

export default class AuthService extends BaseService {
    static controllerArray = [];

    static abortAuthSignals = () => {
        this.controllerArray.forEach((controller) => {
            controller?.abort();
        });

        this.controllerArray = [];
    };

    static login(params = {}, signal = null) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return async function (dispatch) {
            const result = await Http.post(`/auth/login`, params, {
                signal: signal || controller?.signal
            });
            const respData = AuthService.getResponse(result);
            if (respData.success) {
                const { data, token, refresh_token } = respData;
                localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
                Http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                dispatch({ type: LOGIN_SUCCESS, payload: { token, refresh_token } });
            }

            return result;
        };
    }

    static register(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/auth/registration`, params, {
            signal: signal || controller?.signal
        });
    }

    static refreshToken(refreshToken, params = {}, signal = null) {
        const controller = new AbortController();
        this.controllerArray.push(controller);
        console.log("refreshToken",refreshToken);
        return async function (dispatch) {
            try{
                const result = await axios.post(`/auth/refresh`, params, {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    },
                    signal: signal || controller?.signal
                });
                console.log("result", result);
                const respData = AuthService.getResponse(result);
                console.log("respData", respData);
                const { token } = respData;
                Http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                dispatch({ type: REFRESH_SUCCESS, payload: { token } });
                
    
                return result;
            } catch (error) {
                // Handle token refresh error
                throw error;
            }
        };
    }
}
