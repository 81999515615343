import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { TableData } from './style';
import { Icon } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';

const DataTable = ({ columns, data }) => {
  return (
    <TableData>
        <TableContainer component={Paper} sx={{padding: '0px 18px'}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align='center' className='tableHead'>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell align='center' sx={{textAlign: 'center'}}>No data available</TableCell>
                </TableRow>
              ) : (
                data.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align='center'>{row[column.id]}</TableCell>
                      // <TableCell key={column.id} align='center'>
                      //   {column.icon ? (
                      //     <>
                      //       <StorefrontIcon/>
                      //       {row[column.id]}
                      //     </>
                      //   ) : (
                      //     row[column.id] // Otherwise, render cell value
                      //   )}
                      // </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
    </TableData>
  );
};

export default DataTable;