import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import { useTheme } from '@mui/material/styles';

const MapChart = ({width = '100%', height = "500px", pointData = []}) => {
  const theme = useTheme();
  console.log(theme);

  useEffect(() => {
    let root = null;
    am5.ready(() => {
      // Create root element

      root = am5.Root.new("chartdiv");
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create the map chart
      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          projection: am5map.geoMercator(),
        })
      );

      // chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

      // Create main polygon series for countries
      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"]
        })
      );

      polygonSeries.mapPolygons.template.setAll({
        fill: am5.color(theme.colors.invertColor.secondary),
        stroke: null
      });

      // Create point series for markers
      const pointSeries = chart.series.push(am5map.ClusteredPointSeries.new(root, {}));

      // Set clustered bullet
      pointSeries.set("clusteredBullet", (root) => {
        const container = am5.Container.new(root, {
          cursorOverStyle: "pointer"
        });



        container.events.on("click", (e) => {
          pointSeries.zoomToCluster(e.target.dataItem);
        });

        return am5.Bullet.new(root, {
          sprite: container
        });
      });

      // Create regular bullets
      pointSeries.bullets.push(() => {
        const circle = am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.color(theme.colors.color.primary),
          tooltipText: "{title}"
        });

        return am5.Bullet.new(root, {
          sprite: circle
        });
      });

      // Set data

      for (let i = 0; i < pointData.length; i++) {
        const city = pointData[i];
        addCity(city.longitude, city.latitude, city.title);
      }

      function addCity(longitude, latitude, title) {
        pointSeries.data.push({
          geometry: { type: "Point", coordinates: [longitude, latitude] },
          title: title
        });
      }
    });

    return () => {
      if (root) {
        root.dispose();
      }
    }
  }, [theme.palette.mode, pointData]);

  return (
    <div id="chartdiv" style={{ width, height }}></div>
  );
};

export default MapChart;
