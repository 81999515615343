const getAPIURL = () => {
    // const isServer = typeof window === 'undefined';
    // const baseURL = isServer ? 'http://localhost:3000' : window.location.origin;
    // const apiURL =
    //     process.env.PUBLIC_API_URL ||
    //     (process.env.NODE_ENV === 'production' ? 'https://betaapi.tangibl.ai' : 'http://localhost:5000');
    // return apiURL;

    // const apiURL =
    //     process.env.PUBLIC_API_URL ||
    //     (process.env.NODE_ENV === 'https://tangiblbe.intellexio.com');
    // return apiURL;

    // return 'https://e839-2409-4060-2d0e-2d4-f249-2c55-2df4-af1a.ngrok-free.app';
    return 'https://tangiblbe.intellexio.com';
};

const apiURL = getAPIURL();

const Config = {
    apiURL: apiURL
};

export default Config;
